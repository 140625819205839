export const theme = {
    colors: {
        white: '#ffffff',
        blue: '#1890FF',
        gray: '#595959',
        middleGray: '#8C8C8C',
        neroGray: '#262626',
        lightGray: '#BFBFBF',
        pureGray: '#FAFAFA',
        lightRed: '#FFF2F0',
        lightRed1: '#FFCCC7',
        red: '#F5222D',
        grayLightWhite: '#D9D9D9',
        lightGrayWhite: '#F5F5F5',
        tabulateItemText: '#EAEAEA',
        lightGreen: '#F6FFED',
        lightGreen1: '#C1EE9F',
        green: '#52C41A',
        black: '#000000',
        default: '#389e0d'
    },
};
