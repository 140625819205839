import { Button } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { theme } from "../../assets/theme/theme";

const IFL = "https%3A%2F%2Fapps.apple.com%2Fru%2Fapp%2Ffk-krasnodar-oficial-noe-prilozenie%2Fid954752037";

export const Preview =  () => {
  const { t } = useTranslation();
  const {search} = useLocation();
  const link = encodeURIComponent(`${decodeURIComponent(IFL)}&${search.split("&").splice(1).join("&")}`);
  const deeplink = `${process.env.REACT_APP_API_MOBILE}/?ibi=${process.env.REACT_APP_API_MOBILE_IBI}&ifl=${IFL}&link=${link}`;

  useEffect(() => {
    if (search) {
      if (navigator.platform === "iPhone") {
        setTimeout(() => {
          window.open(`com.fc.krasnodar.dex://join${search}`, "_self");
        }, 1000);
      } else {
        setTimeout(() => { window.open("https://play.google.com/store/apps/details?id=ru.fckrasnodar.app.finalkrasnodarapplication",
            "_self");
        }, 1000);
      }
    }
  }, [search]);

    return (
        <Container>
            <Header>
              <Title>
                  <Logo />
              </Title>
              <ButtonsWrapper>
                <Button
                onClick={() => {
                  window.open(
                  "https://apps.apple.com/ru/app/%D1%84%D0%BA-%D1%81%D0%BF%D0%B0%D1%80%D1%82%D0%B0%D0%BA/id1638687708",
                  "_self"
                  );
                }}>
                    {t("common.buttonsText.download")}
                </Button>
                <Button
                  onClick={() => {
                    window.open(deeplink, "_self");
                  }}
                >
                  {t("common.buttonsText.open")}
                </Button>
              </ButtonsWrapper>
            </Header>
        </Container>
    );
};

const Container = styled.div`
    background: ${theme.colors.lightGreen};
`;

const Header = styled.header`
    width: 82.5vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding-top: 2.13vw;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 3.47vw;
    transform: scale(1.7);
    margin-bottom: 40px;
`;

const ButtonsWrapper = styled.div`
  >:nth-child(1) {
    margin-right: 20px;
  }
`;